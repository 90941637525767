import { useState, useEffect, useRef } from 'react';

export const useSliderScroll = (gap = 32, items) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
  const [scrollDeltaPx, setScrollDeltaPx] = useState(50);
  const isScrolledToEnd = scrollLeft >= maxScrollLeft;
  const sliderContainerRef = useRef(null);

  useEffect(() => {
    const sliderContainerNode = sliderContainerRef?.current;
    if (!sliderContainerNode) return;
    setScrollLeft(sliderContainerNode.scrollLeft);
    setMaxScrollLeft(parseInt(sliderContainerNode.scrollWidth) - parseInt(sliderContainerNode.clientWidth));
    const slideWidth = sliderContainerNode?.childNodes?.[0]?.clientWidth || 50;
    const numberVisible = Math.floor(sliderContainerNode?.clientWidth / (slideWidth + gap)) || 50;
    const deltaPx = (slideWidth + gap) * numberVisible;
    if (deltaPx !== scrollDeltaPx) {
      setScrollDeltaPx(deltaPx);
    }
  }, [items, sliderContainerRef?.current, gap, sliderContainerRef?.current?.childNodes?.[0]]);

  const onScrollForward = () => {
    const sliderContainerNode = sliderContainerRef?.current;
    if (!sliderContainerNode) return;

    const slideWidth = sliderContainerNode?.childNodes?.[0]?.clientWidth || 50;
    let offset = 0;
    if (scrollLeft && scrollLeft % (slideWidth + gap) !== 0 && maxScrollLeft - scrollLeft > scrollDeltaPx) {
      const numberVisible = Math.floor(scrollLeft / (slideWidth + gap));
      offset = scrollLeft - numberVisible * (slideWidth + gap);
    }
    sliderContainerNode.scrollBy(scrollDeltaPx - offset, 0);
  };

  const onScrollBack = () => {
    const sliderContainerNode = sliderContainerRef?.current;
    if (!sliderContainerNode) return;
    const slideWidth = sliderContainerNode?.childNodes?.[0]?.clientWidth || 50;
    let offset = 0;
    if (scrollLeft && scrollLeft % (slideWidth + gap) !== 0 && scrollLeft > scrollDeltaPx) {
      const numberVisible = Math.floor(scrollLeft / (slideWidth + gap));
      offset = scrollLeft - (numberVisible + 1) * (slideWidth + gap);
    }
    sliderContainerNode.scrollBy(-scrollDeltaPx - offset, 0);
  };

  const onScroll = () => {
    const sliderContainerNode = sliderContainerRef?.current;
    if (!sliderContainerNode) return;
    setScrollLeft(sliderContainerNode.scrollLeft);
  };

  return {
    onScrollForward,
    onScrollBack,
    scrollLeft,
    isScrolledToEnd,
    sliderContainerRef,
    onScroll,
  };
};
