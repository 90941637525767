import React from 'react';
import { SLIDER_CUSTOM_NAV } from './Sliders.def';
import loadable from '@loadable/component';

const SlidersCustom = loadable(() => import('./SlidersCustom'));
const SlidersDefault = loadable(() => import('./SlidersDefault'));

const Sliders = ({ type, ...props }) => {
  if (type === SLIDER_CUSTOM_NAV) return <SlidersCustom {...props} />;
  else return <SlidersDefault {...props} />;
};

export default Sliders;
