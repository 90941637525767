import React from 'react';
import { ReactComponent as View } from '../../images/icons/view.svg';
import { FormattedMessage } from 'react-intl';
import useAdultContentHandlers from '../../utils/useAdultContentHandlers';
import classNames from 'classnames';
import styles from './ToggleAdultContent.module.scss';

const ToggleAdultContent = ({ classList }) => {
  const { adultContentAllowed, adultContentBlocked, allowAdultContent, forbidAdultContent } = useAdultContentHandlers();

  const hideAdultContent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    forbidAdultContent();
  };

  const showAdultContent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    allowAdultContent();
  };

  if (adultContentBlocked) {
    return (
      <span className={classNames(classList.root, classList.blocked || styles.blocked)}>
        <FormattedMessage id="product.adultTitle" defaultMessage="18+ content" />
      </span>
    );
  }
  if (adultContentAllowed) {
    return (
      <span className={classList.root} onClick={hideAdultContent}>
        <View />
        <FormattedMessage id="product.adultHide" defaultMessage="Hide adult content" />
      </span>
    );
  }

  return (
    <span className={classList.root} onClick={showAdultContent}>
      <View />
      <FormattedMessage id="product.adultShow" defaultMessage="Show adult content" />
    </span>
  );
};

export default ToggleAdultContent;
