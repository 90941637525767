import { getValidResponseBody, staticApi } from '../../api';
import { isProduction } from '../../utils/isProduction';

let categories = null;

const fetchData = async () => {
  let response = null;
  try {
    const data = await staticApi.getCategoriesRoutes();
    response = getValidResponseBody(data);
  } catch (e) {
    console.error('Error fetching top level categories', e);
  }
  return response || null;
};

export const loadData = async () => {
  if (!isProduction) console.info('refreshing top level category data');
  categories = await fetchData();
  if (!isProduction) console.info('refreshing top level category data done');
  return categories;
};

export const initCategoryRoutesFetcher = async () => {
  if (!isProduction) console.log('CRF init');
  await loadData();
  //affects TTI
  //setInterval(loadData, 10 * 60 * 1000);
};

export const getCategoriesRoutes = () => categories;
